<template>
    <small
        v-if="isSingleDay"
        v-text="startTime"
    />

    <small
        v-else
        v-text="$t('COURSE.SESSION.STARTING_AT_TIME', { time: startTime })"
    />
</template>

<script setup lang="ts">
    import { computed } from 'vue'

    import { getTimeRange } from '../utils'

    interface Props {
        value: SessionModel | SessionAttentionModel
        formatDay?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        formatDay: (): string => 'dddd',
    })


    const isSingleDay = computed((): boolean => props.value?.dates.length === 1)
    const startTime = computed((): string => getTimeRange(props.value))
</script>
