<template>
    <b-dropdown-item
        v-if="tag === 'dropdown'"
        :href="url"
        target="_blank"
    >
        <fa
            v-if="!noIcon"
            :icon="['fal', 'fa-download']"
        />
        <span
            v-if="label"
            v-text="label"
        />
    </b-dropdown-item>

    <btn
        v-else
        :tag="tag"
        :href="url"
        :variant="variant"
        :icon="noIcon ? null : ['fal', 'download']"
        :label="label"
        :size="size"
        :pill="pill"
        target="_blank"
        download
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { BDropdownItem } from 'bootstrap-vue'
    import { buildFullPath } from '../utils'

    export default Vue.extend({
        components: {
            BDropdownItem,
        },

        props: {
            path: {
                type: String as PropType<string>,
                required: true,
            },

            label: {
                type: String as PropType<string>,
                default: null,
            },

            noIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            size: {
                type: String as PropType<string>,
                default: 'md',
                validator: (value: string): boolean => ['sm', 'md', 'lg'].includes(value),
            },

            pill: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /** Specify the HTML tag to render instead of the default tag */
            tag: {
                type: String as PropType<string>,
                default: null,
            },
        },

        computed: {
            url(): string | null {
                return buildFullPath(this.path)
            },

            variant(): string | null {
                return this.tag === 'a' ? 'link' : 'default'
            },
        },
    })
</script>
